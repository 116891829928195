.my_page_links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 4px 4px 7px -2px rgba(53, 53, 53, 0.66);
}

.header_brand {
  max-height: 6vh;
}

.my_page_links p {
  padding-left: 0.2em;
  padding-right: 0.2em;
  font-size: 1.7em;
}

.my_page_links a {
  color: black;
}
a {
  text-decoration: none;
}
a:hover {
  color: blue;
}
.navbar_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;

  box-shadow: 4px 4px 7px -2px rgba(53, 53, 53, 0.66);
}

.navbar_container button {
  margin-top: 0.5em;
  margin-right: 0.1em;
  margin-left: 0.1em;
  border-radius: 1em;
  outline: none;
  min-width: 6em;
  min-height: 3em;
  /* height:3.7vh; */
  /* font-size: 1em; */
}

@media only screen and (max-width: 600px) {
  nav {
    font-size: 1.5em;
  }
}
@media only screen and (max-width: 760px) {
  .header_brand {
    display: none;
  }
}

.navbar_button_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
}
.navbar_container img {
  height: 4em;
  max-height: 8vh;
  padding: 5px;
}
