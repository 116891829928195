.new_player_form_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.new_player_form_container form input{
    outline:none;
}

.new_player_form_container form{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 85vw;
        font-size: 2.5rem ;
    justify-items: center;
    display: grid;
    padding-bottom: 1em;
}

.new_player_form_container form div{
    padding:.1em;
    
}

#register_button{
    width: 16.2em;
}
.chakra-select__icon-wrapper  svg{
    display: none;
  }

.new_player_form_container form select {
    width: 11.3em;
}
input[type='checkbox'] {
    width:1em!important;
    height:1em;
    
}

.positions_checkboxes{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    flex-direction: column;
    /* flex-wrap: wrap; */
    /* width:12em; */
    
}
.positions_checkboxes label{
    margin-left: .1em;
    margin-right: .1em;
}
.positions_checkboxes input[type='checkbox']{
    width: 1em!important;
    height: 1em!important;
}

.chakra-input {
    border: solid 2px #3f51b5;
    border-radius: 10px;
}

@media only screen and (max-width: 600px) {

    .new_player_form_container form{
        font-size: 1.5rem ;
        
        
    }
    }
