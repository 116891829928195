*{
    font-family: Roboto;
    
}
.players_library_container{
    display: flex;
    justify-content: center;
}
.MuiDataGrid-colCellTitleContainer svg {
    visibility: hidden !important;
}
#tracker_button{
    /* background-color: rgb(240, 236, 236); */
    border-radius: .3em;
    margin-right: 5px;
    margin-bottom: 5px;

}

.MuiDataGrid-main,.MuiDataGrid-root {
    /* border: solid 2px #3f51b5; */
    border-radius: 10px !important;


}

.MuiDataGrid-root .MuiDataGrid-row {
    cursor: pointer;
}

#search_bar{
    border-radius: 10px;
    padding: 4px;
    border: solid 2px #3f51b5;
    outline-style: none;
}