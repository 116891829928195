.help_page_container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.help_page_container div{
    padding: 1em;
    margin-left: .1em;
    margin-right: .1em;
    margin-bottom: .1em;
}
.help_page_container h1 {
    text-align: center;
    text-decoration: underline;
}
.primary_color {
    color:#3f51b5
}