.note_input_container{
    /* width: 50em; */
    width: 85vw;
}

.word_descripitions_container{
    display: flex;
    /* height: 10em; */
}

.word_descripitions_container div {
    display: flex;
    flex-direction: column;
    margin-right: .5em;
}