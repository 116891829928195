.player_evaluation_and_player_notes_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.player_evaluation_and_player_notes_container input{
    outline:none;
}

.abilities_notes {
    display: flex;
    flex-direction: column;
}
.eval_nav_buttons {
    text-align: center;
}

.eval_nav_buttons button{
    margin-right: .5em;
    min-width: 13em;
    font-size: 1.5em;
    margin-bottom: 2em;
}

#title_for_forms{
    text-align: center;
    text-decoration: underline;
    margin-top: .5em;
    margin-bottom: 1em;
}


