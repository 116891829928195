.login_container,
.div__container_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 2em; */
    font-family: Roboto;
    width: 100%;
    background-attachment: absolute;
    background: url('https://portcitydaily.com/wp-content/uploads/2018/08/Baseball-Port-City-Daily.jpg ');
    background-size: cover;
    background-position: top;
    height: 100vh;
    /* background-image: url('https://portcitydaily.com/wp-content/uploads/2018/08/Baseball-Port-City-Daily.jpg center center'); */

}
.login_form_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em;
    margin-top: 2em;
    align-self: center;
    /* border-radius: 2em; */
    border: 1px solid lightgray;
    box-shadow: 4px 4px 7px -2px rgba(243, 243, 243, 0.66);  
    background-color: white; 
}

.login_form_container form {
    display: flex;
    flex-direction: column;
    border-radius: 1em;
}

.login_form_container img {
background-color: white;
}
#bb_letters{
    /* width: 80%; */
    align-self: center;
}
.form_for_login button{
    margin-top: .5em;
    border-radius: 1em;
    background-color: #2d4cb8;
    color: white;
    font-weight: bold;
    font-size: 1.1em;
    outline: none;

}
.form_for_login input {
    margin-top: .5em;
    border-radius: 1em;
    padding: .6em;
    width: 14.5em;
    outline: none;
}

.splash_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.splash_container div{
    padding: 2.5em;
}
.splash_container div div{
    text-align: center;
}
.splash_container h6{
    font-family: cursive;
    text-decoration: underline;
}
.splash_buttons{
    display: flex;
    justify-content: center;
    align-items: flex-end;

}
.splash_buttons button{
    margin-left: 1em;
    margin-right: 1em;
}

.forgot_password{
    text-align: center;
    font-size: .6em;
    cursor: pointer;
}

#invalid_login{
    color: red;
    font-weight: bold;
    text-decoration: underline;
}