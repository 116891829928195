footer {
    font-family: Roboto;
    border-radius: .2em;
    font-size: 1.2em;
    font-weight: bold;
    position: fixed;
    opacity: .5;
    left: 0;
    bottom: 0;
    max-height: 6vh;
    width: 20em;
    display: flex;
    justify-content: flex-start;
    margin-left: 2em;
}
footer .my_page_links {
    box-shadow: none!important;
}
footer div a{
    margin-left: .2em;
    margin-right: .2em;
    color:black;
}