.profile_picture{
    height: 10em;
    width: 10em;
    border-radius: 2em;
}

input[type='file'] {
    /* display: none; */
    opacity: 0;
    background-color: white;
    position: absolute;
    z-index: -100;
  }
#cloud_button{
    border: none;
    background: white;
    color: #3f51b5;
}
#cloud_button svg{
    font-size:4em;
}
#file_upload{
    color:#3f51b5;
    border-radius: .3em;
    z-index: -1;
    width:10em;
    text-align: left;
    position: absolute;
    margin: auto;
    
}
#file_upload::before{
    background-color: black;
}
.new_video{
    color: #3f51b5;
}
#file_upload svg{
    font-size: 4em;
    
}
.player_profile_page{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:3em;
    /* justify-content: center; */
    height: 100vh;
}

.player_profile_container{
    display: flex;
    flex-direction: row;
    border-radius: 1em;
    align-items: flex-start;
    justify-content: center;
    max-width: 30vw;
    /* height: 50vh; */
    padding: 1em;
}

.player_info_container{
    display: flex;
    flex-direction: row;
    padding: 3em;

}
.player_info_container div {
    padding: 1em;
}
.player_profile_container_image{
    display: flex;
    flex-direction: column;
    margin-right: 1em;
    justify-self: flex-start;
    align-self: flex-start;
    justify-content: flex-start;
}

.file_upload_container form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.file_upload_container form span{
    /* width: 1vw; */
    padding: 0;
    border-radius: 1em;
}
.file_upload_container{
    width: 1vw;
}

#card_info_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:left;
    justify-content: space-around;
    padding: 1em;
}

.video_container {
    display: flex;
    justify-content: center;
    /* position: relative; */
    flex-wrap: wrap;
    /* width: 65em;
    border-radius: .5em;
    height: 10em; */
    /* border-top: 1px solid lightgray; */
    /* box-shadow:5px 5px 15px 5px #B5B5B5; */
    /* overflow-y: scroll; */
    /* overflow-x: scroll; */
}

/* .video_container div {
    border-bottom: 1px solid lightgray;
} */
.player_videos{
    margin-top:.5em ;
}
.player_videos form {
    display: flex;
    justify-content: center;
    margin-top: .5em;
    margin-bottom: .5em;
}

.picture_modal_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.delete_button_pic_video{
    margin-top: .5em;
    width: 6em;
    height: 3em;
    background-color: red;
    border-radius: 1em;
    color: white;
}

#new_media:hover{
    top: 10px;
}
.print_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pdf_container{
    display: flex;
    margin-top: 3em;
    flex-direction: row;
    justify-content: flex-start;
}

.pdf_media_links_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: flex-start; */
    /* flex-wrap: wrap; */
    width:35em;
}

.pdf_media_links_container ul {
    display: flex;
    padding: 0;
    font-size: .8em;
   flex-direction: column;
   align-items: flex-start;
   list-style: none;
}

.pdf_container_buttons{
    display: flex;
    flex-direction: row;
    /* flex-direction: column; */
    /* width:10em; */
    /* height: 3em; */
    justify-content: center;
}

.pdf_container_buttons button {
    width: 10em;
    margin-left: .1em;
    margin-right: .1em;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
}
.evalContainer{
    display: flex;
    justify-content: flex-start;
}
.pdf_eval_nonpitcher{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
    /* justify-content: flex-start; */
    /* margin-right: 12em; */
    margin-top: 2em;
    /* width: 50em; */
    font-weight: bold;
}
.pdf_eval_pitcher{
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
    margin-top: 2em;
    margin-right: 2em;
    font-weight: bold;
}

.pdf_nonpitcher_category{
    display: flex;
    width: 15em;
    font-size: .7em;
    justify-content: space-between;
}
#text_bar{
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
}
.pdf_pitcher_category_text{
    display: flex;
}
.pdf_pitcher_category_text div{
    display: flex;
    margin-right: .3em;
}
.eval_and_links_pdf{
    display: flex;
}
.pdf_pitcher_category{
    display: flex;
    font-size: .7em;
    width: 15em;
    justify-content: space-between;
}

#category_title{
    text-decoration: underline;
    color:rgb(14, 75, 145);
}

.pdf_notes_container{
    /* display: flex; */
    align-self: center;
    /* justify-content: center; */
    /* width: 50%; */
}
.category_container_notes{
    display: flex;
}
.pdf_notes_container div{
    margin-right:.5em ;
    margin-left:.5em ;
}
.loader_container{
    /* height:50px;
    width: 50px; */
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
     z-index: 100;
  transform: translate(-50%, -50%);
}
.loader_container h1{
    color: red;
}
#profile_back_button{
    padding-right:1em;
    padding-left:1em;
    margin-right:1em;
}
.player_profile_container_image {
    border-radius: 10px;
}
